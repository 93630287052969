<template>
  <div class="box">
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed': ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="">
    </div>
    <img class="banner" src="/subject/newYear/wap/banner.png" alt="">
    <div class="animation">
      <img style="width:18px;margin-right:5px" src="/subject/newYear/notice.png" alt="">
      <div class="text-container">
        <transition class="inner-container" name="slide" mode="out-in">
          <p class="text2" :key="newsText.id">{{newsText.val}}</p>
        </transition>
      </div>
    </div>
    <div class="centerBox">
      <div class="part-1">
        <img style="width:95%" src="/subject/newYear/title1.png" alt="">
        <img @click="checkActive(true)" style="margin:5px auto 16px;width: 110px;" src="/subject/newYear/rule.png" alt="">
        <div class="welfare1">
          <img @click="openLogin" src="/subject/newYear/redPackage.png" alt="">
        </div>
        <img style="margin:26px auto;width: 205px;" src="/subject/newYear/welfare2Title.png" alt="">
        <div class="welfare2">
        </div>
        <img style="margin:15px auto;width: 80%;" src="/subject/newYear/double2.png" alt="">
        <img @click="openLogin" style="margin-bottom:10px;cursor: pointer;width: 254px;" src="/subject/newYear/rightNowGo.png" alt="">
        <p>福兔限时送金，就问你冲不冲</p>
      </div>
      <div class="part-2">
        <img style="width:95%" src="/subject/newYear/title2.png" alt="">
        <p style="margin:10px auto">伦敦金/银投资， 门槛低易上手</p>
        <img style="margin:5px auto;width: 95%;" src="/subject/newYear/wap/part2banner.png" alt="">
        <div class="bottomKKK">
          <p>人生起起落落，投资涨涨跌跌</p>
          <p>选对方法，来年扬眉 <span> “兔” </span> 气</p>
        </div>
      </div>
      <div class="part-3">
        <img style="width:95%" src="/subject/newYear/title3.png" alt="">
        <img style="margin:10px auto 5px;width: 95%;" src="/subject/newYear/wap/part3banner1.png" alt="">
        <img style="width: 95%;" src="/subject/newYear/wap/part3banner2.png" alt="">
        <img @click="openRegister" style="margin-top:13px;cursor: pointer;width: 254px;" src="/subject/newYear/register.png" alt="">
      </div>
      <div class="part-4">
        <img style="width:95%" src="/subject/newYear/title4.png" alt="">
        <div class="part4box">
          <div>
            <img class="position1" src="/subject/newYear/1.png" alt="">
            <img class="position2" src="/subject/newYear/2.png" alt="">
            <img class="position3" src="/subject/newYear/3.png" alt="">
            <img class="position4" src="/subject/newYear/4.png" alt="">
            <img class="position6" src="/subject/newYear/6.png" alt="">
            <img class="position5" src="/subject/newYear/5.png" alt="">
          </div>
          <img class="phone" src="/subject/newYear/phone.png" alt="">
        </div>
        <div class="code">
          <!-- <img src="/subject/newYear/ios.png" alt="">
          <img src="/subject/newYear/Android.png" alt=""> -->
        </div>
        <p>咨询客服，获取最新版本</p>
        <img @click="live800" style="width:254px;margin-top: 10px;" src="/subject/newYear/wap/register.png" alt="">
      </div>
    </div>
    <contactusVue v-if="!isApp"></contactusVue>
    <div class="fixedBox">
      <img class="allCode" src="../../../../static/common/allCode.png" alt="">
      <img @click="live800" class="hours" src="/subject/newYear/24h.png" alt="">
      <img @click="downLoad" class="downLoad" src="/subject/newYear/downLoad.png" alt="">
    </div>
    <div v-if="showDialog" class="dialog">
      <img class="closer" @click="checkActive(false)" src="/subject/newYear/guanbi2.png" alt="">
      <ul class="dialogContent">
        <li>
          <span>活动对象：</span>
          活动期间在本公司开立真实账户或首次入金的客户
        </li>
        <li>
          <span>活动时间：</span>
          即日起至{{this.getLastDay()}}23:59;
        </li>
        <li>
          <span>活动规则：</span><br>         
          活动期间，新用户可享双重福利：<br>
          1.	开户赠送$500现金券一张，盈利即送3美金；<br>
          2.	首次入金，充多少送多少，交易可返现，<span style="color:#e34a3f">上不封顶</span>；
        </li>
        <li>
          <span>开户领现金券活动：</span><br>
          1. 活动期间，开立真实账户赠送500美元现金券一张；<br>
          2. 现金券领取后可直接交易，使用有效期从当日00:00起计算，共计3天；<br>
          3. 每张现金券仅可交易1笔，现金券持仓单需在最近一次04:00前平仓，或由系统自动平仓；<br>
          4. 现金券交易后盈利即送3美金，直接发放到MT账户，用户可直接提现；<br>
          5. 盈利有效期为平仓后30天内，逾期扣回；<br>
          6. 现金券仅限伦敦金/伦敦银交易品种；<br>
        </li>
        <li>
          <span>首入金 充多少送多少：</span><br>
          1. 活动期间内，首次入金≥对应金额，即可获得对应金额美元赠金（即赠金点）回馈；<br>
          2. 首入金有效期为第一笔入金后的72h内，赠送金额将按照72h后最终数额计算；<br>
          3. 每交易一手伦敦金/伦敦银，返还相对应赠金金额，该笔资金可交易可提现，用户可登录App-我的-赠金奖励查看（未满一手按照实际交易手数占一手的比例计算）；<br>
          4. 美元赠金按月结算，当月交易越多，每手返还越高，赠金返还不分账户等级；<br>
          <!-- 5. 可提取赠金需在用户中心进行提取，提取后返还至客户MT4真实交易账户中，赠金可提现/交易；<br> -->
          5. 每手可兑换美元赠金金额如下：<br>
        </li>
        <li>
          <table>
            <tr>
              <th>当月交易</th>
              <th>每手可兑换</th>
              <th>每档总赠金</th>
            </tr>
            <tr>
              <th>第0~3手</th>
              <th>$2美金</th>
              <th>3*2=6</th>
            </tr>
            <tr>
              <th>第3.01~6手</th>
              <th>$3美金</th>
              <th>3*3=9</th>
            </tr>
            <tr>
              <th>第6.01~50手</th>
              <th>$4美金</th>
              <th>44*4=176</th>
            </tr>
            <tr>
              <th>第50.01手以上</th>
              <th>$5美金</th>
              <th>(当月手数-50)x5</th>
            </tr>
          </table>
        </li>
        <li>※举例：A 交易 8 手，可兑换赠金＝(3x2)+(3x3)+(2x4)=23 美元.</li>
        <li>
          <span>赠金明细：</span><br>
          <table>
            <tr>
              <th>赠送条件</th>
              <th>赠金明细</th>
            </tr>
            <tr>
              <th>首入金≥100</th>
              <th>100美元赠金</th>
            </tr>
            <tr>
              <th>首入金≥$200</th>
              <th>200美元赠金</th>
            </tr>
            <tr>
              <th>首入金≥500</th>
              <th>500美元赠金</th>
            </tr>
            <tr>
              <th>首入金≥$1000</th>
              <th>1000美元赠金</th>
            </tr>
            <tr>
              <th>首入金≥5000</th>
              <th>5000美元赠金</th>
            </tr>
            <tr>
              <th>首入金≥$10000</th>
              <th>10000美元赠金</th>
            </tr>
          </table>
          <span style="color:#e34a3f">首入金金额超过$10000，赠金金额以$1000为单位递增，上不封顶</span>
        </li>
        <li>
          <span>注意事项：</span><br>
          1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br>
          2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br>
          3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br>
          4. 凡推广商及其名下客户不参与本活动；<br>
          5. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
        </li>
      </ul>
    </div>
    <div class="dark" v-if="showDialog" @click="()=>{showDialog = false}"></div>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
export default {
  name:'newYearWap',
  components:{
    ContactusVue
  },
  data(){
    return{
      navBarFixed: false,
      scroll: Number,
      screenWidth:null,
      showDialog: false,
      news: [
        { id: "1", value: "用户 92xxx34 刚刚提现3美金" },
        { id: "2", value: "用户 96xxx68 刚刚提现3美金" },
        { id: "3", value: "用户 98xxx41 刚刚提现3美金" },
        { id: "4", value: "用户 91xxx24 刚刚提现3美金" },
        { id: "5", value: "用户 93xxx30 刚刚提现3美金" },
      ],
      number: 0,
      isApp: false,
    }
  },
  created(){
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow',true);
    // 判断是从A前还是A后跳转过来
    if(!sessionStorage.getItem('craig')){
      if(!this.$route.query.craig){
        sessionStorage.setItem('craig',window.location.origin);
      }else{
        sessionStorage.setItem('craig',this.$route.query.craig);
      }
    };
    if(this.$route.query.isApp){
      this.isApp = true;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    this.startMove();
  },
  computed: {
    newsText() {
      return {
        id: this.number,
        val: this.news[this.number].value
      }
    }
  },
  methods:{
    // 控制顶部导航栏的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    // 返回上级页面
    goback(){
      window.location.href = sessionStorage.getItem('craig');
    },
    // 查看活动
    checkActive(attr){
      this.showDialog = attr;
    },
    // 客服链接
    live800(){
      // window.open('https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377');
      window.open(this.$parent.live800UrlUc)
    },
    downLoad(){
      window.open('https://oss.0790jiaxiao.com/HS/software/hs_9999.apk');
    },
    openLogin(){
      window.open('https://www.rlcvipltd.net/uc/login');
    },
    openRegister(){
      window.open('https://www.rlcvipltd.net/uc/signUp/real');
    },
    // 在定时器中不断改变计算属性对应的值
    startMove() {
      this.number = 0;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.number < this.news.length - 1) {
          this.number += 1;
        } else {
          this.number = 0;
        }
      }, 5000);
    },
    //获取当月最后一天的日期
    getLastDay(){
      var year = new Date().getFullYear(); //获取年份
      var month = new Date().getMonth() + 1; //获取月份
      var lastDate = new Date(year, month , 0).getDate(); //获取当月最后一日
      month = month < 10 ? '0' + month : month ; //月份补 0
      return year + '年' + month + '月' + lastDate + '日';
    }
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    .animation{
      width: 100%;
      height: 30px;
      background-color: #fe9871;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .text-container{
        .slide-enter-active, .slide-leave-active {
          transition: all 0.2s linear;
        }
        .slide-leave-to {
          transform: translateY(-10px);
        }
        .slide-enter {
          transform: translateY(10px);
        }
      }
    }
    .fixedBox{
      width: 358px;
      height: 558px;
      background-color: #fd6e72;
      position: fixed;
      top: 50%;
      right: -180px;
      transform: translate(-50%,-50%);
      background: url('/subject/newYear/suspension.png') no-repeat;
      background-size: 100% 100%;
      display: none;
      .allCode{
        width: 160px;
        position: absolute;
        top: 60%;
        left: 51%;
        transform: translate(-50%,-50%);
      }
      .hours{
        position: absolute;
        bottom: 10%;
        left: 51%;
        transform: translate(-50%,-50%);
        cursor: pointer;
      }
      .downLoad{
        position: absolute;
        bottom: 0%;
        left: 51%;
        transform: translate(-50%,-50%);
        cursor: pointer;
      }
    }
    .tabbar{
      width: 100%;
      background-color: #ffffff;
      img{
        margin: 10px 20px;
        width: 110px;
      }
    }
    .navBarFixed{
      position: fixed;
      top: 0;
      z-index: 999;
    }
    .banner{
      width: 100%;
    }
    .centerBox{
      width: 100%;
      background-color: #fd6e72;
      background: url('/subject/newYear/wap/bigBackground.png') no-repeat;
      background-size: cover;
      .part-1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 15px 0;
        .welfare1{
          width: 95%;
          height: 300px;
          background: url('/subject/newYear/wap/background1.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 190px;
            cursor: pointer;
          }
        }
        .welfare2{
          width: 95%;
          height: 195px;
          background: url('/subject/newYear/wap/welfare2background.png') no-repeat;
          background-size: 100% 100%;
        }
        p{
          color: #ffe86a;
          font-size: 18px;
        }
      }
      .part-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-top: 20px;
        p{
          color: #ffe86a;
          font-size: 14px;
        }
        .bottomKKK{
          width: 100%;
          height: 110px;
          background: url('/subject/newYear/bottomKKK.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p{
            color: #feedbf;
            font-size: 18px;
            margin-top: 10px;
            span{
              color: #fdf519;
              font-size: 22px;
              margin: 0 10px;
            }
          }
        }
      }
      .part-3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 20px 0;
      }
      .part-4{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-bottom: 20px;
        .part4box{
          width: 100%;
          margin: 15px auto;
          display: flex;
          justify-content: space-around;
          align-items: center;
          div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
              height: 26px;
              margin: 13px auto;
            }
          }
          .phone{
            width: 168px;
          }
        }
        .code{
          img{
            width: 100px;
            margin: 5px 25px 0;
          }
        }
        p{
          color: #feedbf;
          font-size: 18px;
          text-align: center;
          margin-top: 10px;
          line-height: 36px;
          span{
            color: #fdf519;
            font-size: 22px;
            margin: 0 10px;
          }
        }
      }
    }
    .dialog{
      position: relative;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 95%;
      height: 400px;
      box-shadow:0px 0px 95px 0px rgba(187, 188, 192, 0.1);
      background-color: #fdf5e6;
      border-radius: 15px;
      overflow-y: scroll;
      z-index: 99999999;
      .closer{
        width: 20px;
        height: auto;
        position: absolute;
        top: 8px;
        right: 8px;
      }
      .dialogContent{
        box-sizing: border-box;
        padding: 25px 15px 15px;
        overflow: hidden;
        li{
          font-size: 14px;
          color: #a86e4f;
          line-height: 24px;
          span{
            font-weight: 600;
            color: #e34a3f;
          }
          img{
            width: 100%;
            height: auto;
            margin: 20px 0;
          }
          table{
            width: 100%;
            border: 1px solid #e34a3f;
            border-radius: 10px;
            tr{
              th{
                text-align: center;
                font-weight: 500;
                line-height: 24px;
                border-bottom: 1px solid #d2a765;
                border-right: 1px solid #d2a765;
                padding: 5px;
              }
              th:last-child{
                border-right: 1px solid transparent;
              }
            }
            tr:nth-child(1){
              th{
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .dark{
      width: 100%;
      height: 100%;
      background: #000;
      opacity: .5;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 101;
    }
  }
</style>